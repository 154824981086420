import React, { Component, createContext, useContext } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import { useHotkeys } from 'react-hotkeys-hook';
import { themeGet } from 'styled-system';
import { timingFunctions } from 'polished';

import VideoPlayer from './VideoPlayer';
import InlineSVG from './InlineSVG';
import iconClose from '../../static/img/icons/x.svg';

export const Context = createContext({});

const GlobalStyle = createGlobalStyle`
  ${props => props.isOpen && css`
    html {
      overflow: hidden;
    }
    body {
      overflow: hidden;
      -webkit-overflow-scrolling: touch;
    }
  `}
`;

const Wrapper = styled.div``;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  background: hsla(0, 0%, 5%, 0.8);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  pointer-events: none;
  transition: opacity 500ms;
  ${props => props.isOpen && css`
    opacity: 1;
    pointer-events: all;
    z-index: 9999999999;
  `}
`;

const BackgroundBrandColor = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  left: 0;
  height: 100%;
  background: ${themeGet('colors.secondary.base')};

  transition: top 1000ms ${timingFunctions('easeOutCirc')};
  ${props => props.active && css`
    top: 0;
    * { z-index: 9999999999; }
  `}
`;

const Close = styled.img`
  position: absolute;
  z-index: 999999999;
  pointer-events: all;
  top: 25px; right: 25px;
  width: 40px;
  height: 40px;
  margin: 5px;
  cursor: pointer;

  transition: opacity 500ms;
  opacity: 1;

  &:hover { opacity: 0.5; }
`;

const VideoAnimateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 40px;

  opacity: 0;
  transition: opacity 500ms 750ms;
  ${props => props.active && css`
    opacity: 1;
  `}

  @media(min-width: ${themeGet('breakpoints.1')}) {
    padding: 100px;
  }
`;

export const Modal = ({
  brandColor, video, isOpen, onClose,
}) => {
  useHotkeys('esc', onClose);
  return (
    <Wrapper>
      <GlobalStyle isOpen={isOpen} />
      <ModalWrapper isOpen={isOpen}>
        <BackgroundBrandColor
          active={isOpen}
          onClick={onClose}
          brandColor={brandColor}
        />
        <VideoAnimateWrapper
          active={isOpen}
        >
          {isOpen ? <VideoPlayer {...video} open={isOpen} /> : null}
        </VideoAnimateWrapper>
        <Close
          onClick={onClose}
          src={iconClose}
          alt=""
        />
      </ModalWrapper>
    </Wrapper>
  );
};

Modal.propTypes = {
  brandColor: PropTypes.string,
  video: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  brandColor: 'hsl(42,100%,55%)',
  video: {},
  isOpen: false,
  onClose: () => {},
};

export const VideoModalButton = ({
  onClick,
  fill,
  stroke,
  width,
  height,
  brandColor,
}) => (
  <div>
    <InlineSVG
      onClick={onClick}
      fill={fill}
      stroke={stroke}
      width={width}
      height={height}
      brandColor={brandColor}
    />
  </div>
);

VideoModalButton.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  brandColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
};

VideoModalButton.defaultProps = {
  stroke: '#fff',
  fill: 'transparent',
  brandColor: 'hsl(42,100%,55%)',
  width: 30,
  height: 30,
  onClick: () => {},
};

export const VideoModalButtonWithContext = ({ video, ...props }) => {
  const { open } = useContext(Context);
  return (
    <VideoModalButton
      {...props}
      onClick={() => open(video)}
    />
  );
};

VideoModalButtonWithContext.propTypes = {
  video: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object,
  ]),
};

VideoModalButtonWithContext.defaultProps = {
  video: 150110400,
};

export const VideoPlayerWithContext = ({ ...props }) => {
  const { isOpen } = useContext(Context);
  return (
    <VideoPlayer {...props} paused={isOpen} />
  );
};

export default class VideoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      // eslint-disable-next-line react/no-unused-state
      open: this.open,
      // eslint-disable-next-line react/no-unused-state
      close: this.close,
      video: null,
    };
  }

  open = (video) => {
    this.setState({ isOpen: true, video });
  }

  close = () => {
    this.setState({ isOpen: false });
  }

  render() {
    const { children } = this.props;
    const { isOpen, video } = this.state;
    return (
      <Context.Provider value={this.state}>
        {children}
        <Modal
          video={video}
          onClose={this.close}
          isOpen={isOpen}
        />
      </Context.Provider>
    );
  }
}

VideoModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

VideoModal.defaultProps = {
  children: {},
};
