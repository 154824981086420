import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { timingFunctions } from 'polished';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 999999999;
  left: 50%; top: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
  height: 75px;

  cursor: pointer;
  transition: transform 1000ms ${timingFunctions('easeOutCirc')}, opacity 500ms;
  &:hover {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0.9;
  }
  background: ${props => props.brandColor};
  border-radius: 100%;

  & > svg {
    position: relative;
    left: 2px;
    top: 2px;
    width: ${props => props.width}px;
    height: ${props => props.neight}px;
  }
`;

const InlineSVG = ({
  fill, stroke, width, height, onClick, brandColor,
}) => (
  <Wrapper onClick={onClick} brandColor={brandColor}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-play"
    >
      <polygon points="5 3 19 12 5 21 5 3" />
    </svg>
  </Wrapper>
);

InlineSVG.propTypes = {
  onClick: PropTypes.func.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  brandColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

InlineSVG.defaultProps = {
  fill: 'transparent',
  stroke: '#fff',
  brandColor: 'hsl(42,100%,55%)',
  width: 40,
  height: 40,
};

export default InlineSVG;
