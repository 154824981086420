/**
 * Portfolio
 * ---------
 * props:
 *  - data: [objectOf(object)] **required**
 */

import React, { Component } from 'react';
import
styled, { css, createGlobalStyle, ThemeProvider }
  from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Lightbox from 'react-images';
import PropTypes from 'prop-types';
import { themeGet } from 'styled-system';

import AnimationHorizontal from '../../components/Animate';
import Layout from '../../components/Layout';
import Sitewidth from '../../components/Sitewidth';
import { Title, Paragraph } from '../../components/Text';
import Block from '../../components/Block';
import TextBlock from '../../components/TextBlock';
import Contact from '../../components/Footer/Contact';
import Related from '../../components/RelatedPortfolioItems';
import VideoModal, {
  VideoModalButtonWithContext,
  VideoPlayerWithContext,
} from '../../components/VideoModal';

export const GlobalStyle = createGlobalStyle`
  #lightboxBackdrop {
    z-index: 9999999999999 !important;

    [class^='arrow'] {
      display: none;
    }

    [class^='footerCount'] {
      font-family: 'Avenir Next', 'Helvetica Neue', 'Helvetica', 'sans-serif';
      opacity: 0.5;
      margin: 8px;
      white-space: nowrap;
    }
  }
`;

const Overflow = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Header = styled(Block)`
  & > :first-child {
    position: absolute;
    height: 100%;
    z-index: -1;
  }
`;

const ImageWrapper = styled(Block)`
  height: 300px;

  @media(min-width: ${themeGet('breakpoints.1')}) {
    height: 500px;
  }

  @media(min-width: ${themeGet('breakpoints.2')}) {
    height: 700px;
  }
`;

const VideoWrapper = styled(Block)`
  height: 300px;

  @media(min-width: ${themeGet('breakpoints.1')}) {
    height: 500px;
  }

  @media(min-width: ${themeGet('breakpoints.2')}) {
    height: 600px;
  }
`;

const BrandColor = styled.div`
  z-index: -1;
  width: 100%;
  height: 50%;
  margin-bottom: ${themeGet('space.4')};
  border-radius: 10px;
  display: none;

  ${props => props.brandColor && css`
    background: ${props.brandColor};
  `}

  @media(min-width: ${themeGet('breakpoints.1')}) {
    display: block;
    position: absolute;
    right: 10%;
    top: -10%;
    left: auto;
    max-width: 500px;
    margin-bottom: 0;
  }
  @media(min-width: ${themeGet('breakpoints.3')}) {
    right: 13%;
  }

  @media(min-width: ${themeGet('breakpoints.4')}) {
    right: 10%;
  }
`;

const Image = styled(Img)`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
`;

const Project = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  * {
    width: 100%;
  }

  @media(min-width: ${themeGet('breakpoints.2')}) {
    flex-direction: row;
  }

  ${Title} {
    position: relative;
    padding: 50px;

    &:before {
      content: "”";
      position: absolute;
      left: 15px; top: 25px;
      font-size: 80px;
      opacity: 0.2;
    }

    @media(max-width: ${themeGet('breakpoints.1')}) {
      padding: 50px 0 75px 25px;

      &:before {
        left: -10px;
      }
    }
  }
`;

const TextContent = styled(Paragraph)`
  margin-bottom: 30px;
`;

const StyledParagraph = styled.div`
  width: 100%;
  max-width: 800px;
  text-align: center;
  align-self: center;
  padding: 50px 0;
`;

const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, 1fr);
  
  @media(min-width: ${themeGet('breakpoints.1')}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    & > :first-child {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
    }
  }

  * {
    height: 100%;
  }

  ${props => (
    props.isClickable
      ? null
      : css`* { pointer-events: none !important;
    }`
  )}
`;

const GridItem = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  height: 350px;


  @media(min-width: ${themeGet('breakpoints.2')}) {
    &:first-child {
      min-height: 600px;
    }
  }
`;

const Stats = styled(Block)`
  ${props => props.brandColor && css`
    background: ${props.brandColor};
  `}

  ${Image} {
    @media(min-width: ${themeGet('breakpoints.2')}) {
      &:first-child {
        max-height: 600px;
      }
    }
  }
`;

const Numbers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  color: ${themeGet('colors.white.base')};
  white-space: nowrap;

  @media(min-width: ${themeGet('breakpoints.2')}) {
    flex-direction: row;
  }
`;

const Percent = styled.div`
  font-size: ${themeGet('fontSizes.12')};
  font-weight: ${themeGet('fontWeights.bold')};
`;

const CenterContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Label = styled.div`
  font-size: ${themeGet('fontSizes.5')};
  margin-bottom: 30px;
  @media(max-width: ${themeGet('breakpoints.2')}) {
    & > :not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

const Testimonial = styled(Block)`
  align-items: center;
  justify-content: center;
  text-align: center;

  & > div {
    max-width: 800px;
    font-weight: ${themeGet('fontWeights.light')};
    line-height: 1.6;
  }
`;

const BoldParagraph = styled(Paragraph)`
  font-weight: ${themeGet('fontWeights.bold')};
  padding-top: 20px;
`;

class Portfolio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isClickable: true,
      currentImage: 0,
    };
  }

  handleOpenLightbox = (i) => {
    this.setState({
      isOpen: true,
      isClickable: false,
      currentImage: i,
    });
  }

  handleClose = () => {
    this.setState(
      {
        isOpen: false,
        currentImage: 0,
      },
      () => setTimeout(() => this.setState({ isClickable: true }), 100),
    );
  }

  handlePrev = () => {
    this.setState(prevState => ({
      currentImage: Math.max(prevState.currentImage - 1, 0),
    }));
  }

  handleNext = () => {
    const { data } = this.props;
    this.setState(prevState => ({
      currentImage: Math.min(
        prevState.currentImage + 1,
        data.markdownRemark.frontmatter.project.gallery.length - 1
      ),
    }));
  }

  handleChooseDirection = (event) => {
    if (event.nativeEvent.clientX > (window.innerWidth / 2)) {
      this.handleNext();
    } else {
      this.handlePrev();
    }
  }

  render() {
    const { data } = this.props;
    const { markdownRemark } = data;
    const { frontmatter } = markdownRemark;
    const {
      general,
      header,
      project,
      stats,
      testimonial,
    } = frontmatter;

    const {
      isClickable, isOpen, currentImage,
    } = this.state;

    return (
      <Layout>
        <Overflow>
          <VideoModal>
            <Sitewidth py={[4, 5, 6]}>
              <TextBlock
                title={frontmatter.title}
                label=""
                description={`${general.category},<br />${general.subcategory}`}
                as="h1"
              />
            </Sitewidth>
            <Header my={[2, 3, 7]} p={[3, 4]}>
              <AnimationHorizontal direction="btt" delay={200}>
                <BrandColor brandColor={general.brandColor} />
              </AnimationHorizontal>
              <AnimationHorizontal direction="btt" delay={100}>
                {header.cover.type === 'image'
                  ? (
                    <VideoWrapper>
                      {header.cover.video
                      && (
                      <VideoModalButtonWithContext
                        video={{
                          video: parseInt(header.cover.video, 10),
                          controls: false,
                          loop: false,
                          muted: false,
                          playsinline: false,
                        }}
                        brandColor={general.brandColor}
                      />
                      )
                    }
                      <Image
                        fluid={header.cover.image.childImageSharp.fluid}
                        alt={frontmatter.title}
                      />
                    </VideoWrapper>
                  )
                  : (
                    <>
                      <ImageWrapper>
                        <VideoPlayerWithContext video={header.video} />
                        {header.cover.length > 0
                         && (
                         <VideoModalButtonWithContext
                           video={{
                             video: parseInt(header.cover.video, 10),
                             controls: false,
                             loop: false,
                             muted: false,
                             playsinline: false,
                           }}
                           brandColor={general.brandColor}
                         />
                         )
                      }
                      </ImageWrapper>
                    </>
                  )
                }
              </AnimationHorizontal>
            </Header>
            <Sitewidth>
              <Project my={[2, 3, 7]} p={[3, 4]}>
                <AnimationHorizontal direction="btt">
                  <Title as="h2">
                    {project.quote}
                  </Title>
                </AnimationHorizontal>
                <AnimationHorizontal direction="btt" delay={200}>
                  <Image fluid={project.image.childImageSharp.fluid} />
                </AnimationHorizontal>
              </Project>
              <StyledParagraph my={[2, 3, 7]}>
                {project.text && project.text.map(item => (
                  <AnimationHorizontal direction="btt" key={item.content}>
                    <TextContent>
                      {item.content}
                    </TextContent>
                  </AnimationHorizontal>
                ))}
              </StyledParagraph>
              <AnimationHorizontal direction="btt">
                <Grid isClickable={isClickable}>
                  {project.gallery && project.gallery.map((item, index) => (
                    <GridItem
                    // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      onClick={() => { this.handleOpenLightbox(index); }}
                    >
                      <Img fluid={item.galleryImage.childImageSharp.fluid} />
                    </GridItem>
                  ))}
                </Grid>
              </AnimationHorizontal>
            </Sitewidth>
            <GlobalStyle />
            {project.gallery
              && (
              <Lightbox
                backdropClosesModal
                showCloseButton={false}
                imageCountSeparator=" / "
                width={1200}
                images={project.gallery.map(current => (
                  { src: current.galleryImage.childImageSharp.fluid.src }))
                  }
                isOpen={isOpen}
                onClickPrev={this.handlePrev}
                onClickNext={this.handleNext}
                onClose={this.handleClose}
                onClickImage={this.handleChooseDirection}
                currentImage={currentImage}
              />
              )
                }
            <ThemeProvider theme={{ mode: 'dark' }}>
              <Stats
                brandColor={general.brandColor}
                my={[2, 3, 7]}
                p={[4, 5, 6]}
              >
                <Sitewidth px={0}>
                  <Numbers>
                    {stats.numbers && stats.numbers.map((item, index) => (
                      <div key={item.value}>
                        <Percent>
                          <AnimationHorizontal
                            direction="btt"
                            delay={index * 100}
                          >
                            <CenterContent>
                              {item.value} {item.symbol}
                            </CenterContent>
                          </AnimationHorizontal>
                        </Percent>
                        <AnimationHorizontal
                          direction="btt"
                          delay={index * 200}
                        >
                          <CenterContent>
                            <Label>{item.label}</Label>
                          </CenterContent>
                        </AnimationHorizontal>
                      </div>
                    ))}
                  </Numbers>
                  <StyledParagraph my={[2, 3, 5]}>
                    {stats.text && stats.text.map((item, index) => (
                      <AnimationHorizontal
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        direction="btt"
                        delay={index * 100}
                      >
                        <TextContent>
                          {item.content}
                        </TextContent>
                      </AnimationHorizontal>
                    ))}
                  </StyledParagraph>
                  <AnimationHorizontal>
                    <ImageWrapper>
                      {stats.video
                      && (
                      <VideoModalButtonWithContext
                        video={{
                          video: parseInt(stats.video, 10),
                          controls: false,
                          loop: false,
                          muted: false,
                          playsinline: false,
                        }}
                        brandColor={general.brandColor}
                      />
                      )
                    }
                      {stats.image && (
                        <Image
                          fluid={stats.image.childImageSharp.fluid}
                          alt={frontmatter.title}
                        />
                      )}
                    </ImageWrapper>
                  </AnimationHorizontal>
                </Sitewidth>
              </Stats>
            </ThemeProvider>
            {testimonial
              && (
              <Testimonial p={[4, 5]}>
                {testimonial.text && testimonial.text.map((item, index) => (
                  <div key={item.content}>
                    <AnimationHorizontal delay={index * 150}>
                      {item.type === 'regular'
                        ? item.content
                        : <BoldParagraph>{item.content}</BoldParagraph>
                      }
                    </AnimationHorizontal>
                  </div>
                ))}
              </Testimonial>
              )
            }
            <Sitewidth p={[5, 6]}>
              <Related currentTags={general.tags} />
            </Sitewidth>
            <Contact
              label="Ready to buzz?"
              title="Get in touch"
              quote="Got big ideas?
              Something that you know could change the game, make a difference,
              or spark a revolution? Feel free to reach out to us."
              color="default"
              dataText="Contact us"
              to="/contact"
            />
          </VideoModal>
        </Overflow>
      </Layout>
    );
  }
}

Portfolio.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default Portfolio;


export const pageQuery = graphql`
  query PortfolioBySlug($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        general {
          shortTitle
          tags
          category
          subcategory
          brandColor
        }
        header {
          cover {
            type
            image {
              ...imageFragment
            }
            video
          }
          video
        }
        project {
          quote
          image {
            ...imageFragment
          }
          description
          text {
            content
            type
          }
          gallery {
            galleryImage {
              ...imageFragment
            }
          }
        }
        stats {
          numbers {
            value
            symbol
            label
          }
          text {
            content
            type
          }
          image {
            ...imageFragment
          }
          video
        }
        testimonial {
          text {
            content
            type
          }
        }
      }
    }
  }
`;
