import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const VideoLayer = styled.div`
  overflow: hidden;
`;

const Helper = styled.div`
  position: absolute;
  /*  hide controls  */
  /* top: -60px; right: -60px; bottom: -60px; left: -60px; */
  top: 0; right: 0; bottom: 0; left: 0;

  ${props => props.open && css`
    z-index: 10;
  `}

  iframe, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    transform: translateZ(-9999px);
    z-index: -9999;
    height: 1000%;
    top: -450%;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
`;

const Aspect = styled.div`
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  border-radius: 10px;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
`;

const VideoPlayer = ({ open, ...props }) => (
  <Wrapper>
    <Aspect>
      <VideoLayer>
        <Helper open={open}>
          <Vimeo
            loop
            muted
            autoplay
            playsinline
            {...props}
          />
        </Helper>
      </VideoLayer>
    </Aspect>
  </Wrapper>
);

VideoPlayer.propTypes = {
  open: PropTypes.bool,
};

VideoPlayer.defaultProps = {
  open: false,
};

export default VideoPlayer;
